
$primaryColor: #2876D0;
$primaryColorOverlay: rgba(40, 118, 208, 0.12);
$secondaryColor: #0F3551;
$accentColor: #970000;
$grayDarkColor: #0F3551;

$logoGradient: linear-gradient(45deg, $primaryColor 30%, $primaryColor);

$colorBeige: #e6e2e2;
$colorGrey: rgba(232, 232, 232, 0.5);

$logoBackground: url("/images/logo/cloud-transparent-mono.svg") no-repeat center;
$logoBackgroundTablet: url("/images/logo/cloud-transparent-mono-48-48.svg") center / contain no-repeat;
$logoBackgroundLanding: url("/images/logo/cloud-transparent-mono-long.svg") center / contain no-repeat;
$logoBackgroundMobile: url("/images/logo/n21-logo-mobile.svg") center / contain no-repeat;
$navbarLogo: url("/images/logo/navbar-cloud-logo.svg") center / contain no-repeat;

aside.nav-sidebar{
    .sc-branding{
        padding: 0;
        .cloud-logo{
            width: 100%;
            margin: 0;
            padding: 30px;
        }
        .sc-title {
            display: none;
        }
    }

    @media (max-width: 991px) {
        .sc-branding{
            padding: 0;
            .cloud-logo{
                background: $logoBackgroundTablet;
            }
        }
    }
}
body {
    header.top-margin {
        margin-top: 0;
    }
    header {


        .navbar-light {
            #title {
                padding: 0 !important;
            }

            .cloud-logo {
                background: $logoBackgroundLanding !important;
                margin-right: 0 !important;
                margin-top: -0.3rem !important;
                height: 3.5rem !important;
                width: 12rem !important;
                margin-left: -10px;
            }
            .minimal-button .dropdown-toggle {
                margin-top: 3.2px;
            }
            @media (max-width: 991px) {
                .cloud-logo {
                    background: $logoBackgroundMobile !important;
                 }
            }
        }
    }
}

.primary-color{
    color: $primaryColor;
    display: none;
}
.secondary-color {
    color: $secondaryColor;
    display: none;
}
@import "../lib/bootstrap/scss/bootstrap";

@import "./variables";
@import "./layouts";
@import "./header";
@import "./sections";

body {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	min-height: 100vh;
	min-width: 300px;
}
main {
	margin-top: 2.75rem;
	flex-grow: 1;
}

@include media-breakpoint-down(md) {
	main {
		margin-top: 4.7rem;
	}
}

ul {
	list-style-type: square;
	padding: 0 1.2rem;
}

h1.section-title {
	@include media-breakpoint-down(md) {
		font-size: 36px;
	}
}

video {
	border: 1px solid var(--color-white);
}
